import {
    SWITCH_CLOUD_SERVICE_AIVEN,
    SWITCH_CLOUD_SERVICE_AMAZON_MSK,
    SWITCH_CLOUD_SERVICE_AZURE_HD_INSIGHT,
    SWITCH_CLOUD_SERVICE_CONFLUENT_CLOUD,
    SWITCH_CLOUD_SERVICE_INSTACLUSTR,
} from './types'

export const switchCloudService = (selectedService) => (dispatch) => {
    try {
        if (selectedService.name === 'aiven') {
            dispatch({
                type: SWITCH_CLOUD_SERVICE_AIVEN,
                payload: selectedService.checked,
            })
        } else if (selectedService.name === 'amazon_msk') {
            dispatch({
                type: SWITCH_CLOUD_SERVICE_AMAZON_MSK,
                payload: selectedService.checked,
            })
        } else if (selectedService.name === 'azure_hd_insight') {
            dispatch({
                type: SWITCH_CLOUD_SERVICE_AZURE_HD_INSIGHT,
                payload: selectedService.checked,
            })
        } else if (selectedService.name === 'confluent_cloud') {
            dispatch({
                type: SWITCH_CLOUD_SERVICE_CONFLUENT_CLOUD,
                payload: selectedService.checked,
            })
        } else if (selectedService.name === 'instaclustr') {
            dispatch({
                type: SWITCH_CLOUD_SERVICE_INSTACLUSTR,
                payload: selectedService.checked,
            })
        }
    } catch (error) {
        console.log(error)
    }
}
