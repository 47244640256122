import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'

import aws_image from '../../resources/images/cloud/aws.svg'
import azure_image from '../../resources/images/cloud/azure.svg'
import digitalOcean_logo from '../../resources/images/cloud/digitalOcean_logo.svg'
import google_cloud_platform from '../../resources/images/cloud/google_cloud_platform.svg'
import ibm_cloud_logo from '../../resources/images/cloud/ibm_cloud.svg'
import upcloud_logo from '../../resources/images/cloud/upcloud_logo.svg'
import your_cloud from '../../resources/images/cloud/your_cloud.svg'

import { connect } from 'react-redux'
import { switchCloudOption } from '../../actions/cloudOptionsActions'

const CloudOptions = ({ cloudOptions, switchCloudOption }) => {
    const { aws, google_cloud, azure, digital_ocean, ibm_cloud, up_cloud, your_own_cloud } =
        cloudOptions

    const [choicesSelected, setChoicesSelected] = useState({
        selectedChoices: 0,
    })
    const { selectedChoices } = choicesSelected

    const clickedCloud = (e) => {
        switchCloudOption(e.target)
        if (e.target.checked) {
            setChoicesSelected({
                selectedChoices: selectedChoices + 1,
            })
        } else {
            setChoicesSelected({
                selectedChoices: selectedChoices - 1,
            })
        }
    }

    return (
        <Fragment>
            <p className='cera-pro f-24 fw-700 mt-5 mb-4'>
                Which cloud do you want to host your Kafka on? (choose max 3)
            </p>
            <div className='container-1'>
                <div className='d-flex flex-wrap justify-content-center'>
                    <div
                        className={`col-sm col-5 mt-2 shadow mx-2 ${
                            aws ? 'border-teal-cloud' : ''
                        }`}>
                        <label
                            className={`cloud-checkbox-container 
            ${selectedChoices === 3 && !aws ? 'cursor-na' : 'cursor-pointer'}
            `}>
                            <input
                                type='checkbox'
                                name='aws'
                                onChange={(e) => clickedCloud(e)}
                                disabled={selectedChoices === 3 && !aws}
                                checked={aws}
                            />
                            <img
                                className={`img-fluid cloud-selection-image 
                ${selectedChoices === 3 && !aws ? 'cursor-na' : 'cursor-pointer'}
                `}
                                src={aws_image}
                                alt='AWS'
                            />
                            <span className='cloud-checkbox-checkmark'></span>
                        </label>
                    </div>
                    <div
                        className={`col-sm col-5 mt-2 shadow mx-2 ${
                            google_cloud ? 'border-teal-cloud' : ''
                        }`}>
                        <label
                            className={`cloud-checkbox-container 
            ${selectedChoices === 3 && !google_cloud ? 'cursor-na' : 'cursor-pointer'}
            `}>
                            <input
                                type='checkbox'
                                name='google_cloud'
                                onChange={(e) => clickedCloud(e)}
                                disabled={selectedChoices === 3 && !google_cloud}
                                checked={google_cloud}
                            />
                            <img
                                className={`img-fluid cloud-selection-image 
                ${selectedChoices === 3 && !google_cloud ? 'cursor-na' : 'cursor-pointer'}
                `}
                                src={google_cloud_platform}
                                alt='google cloud platform'
                            />
                            <span className='cloud-checkbox-checkmark'></span>
                        </label>
                    </div>
                    <div
                        className={`col-sm col-5 mt-2 shadow mx-2 ${
                            azure ? 'border-teal-cloud' : ''
                        }`}>
                        <label
                            className={`cloud-checkbox-container 
            ${selectedChoices === 3 && !azure ? 'cursor-na' : 'cursor-pointer'}
            `}>
                            <input
                                type='checkbox'
                                name='azure'
                                onChange={(e) => clickedCloud(e)}
                                disabled={selectedChoices === 3 && !azure}
                                checked={azure}
                            />
                            <img
                                className={`img-fluid cloud-selection-image 
                ${selectedChoices === 3 && !azure ? 'cursor-na' : 'cursor-pointer'}
                `}
                                src={azure_image}
                                alt='Azure'
                            />
                            <span className='cloud-checkbox-checkmark'></span>
                        </label>
                    </div>
                    <div
                        className={`col-sm col-5 mt-2 shadow mx-2 ${
                            digital_ocean ? 'border-teal-cloud' : ''
                        }`}>
                        <label
                            className={`cloud-checkbox-container 
            ${selectedChoices === 3 && !digital_ocean ? 'cursor-na' : 'cursor-pointer'}
            `}>
                            <input
                                type='checkbox'
                                name='digital_ocean'
                                onChange={(e) => clickedCloud(e)}
                                disabled={selectedChoices === 3 && !digital_ocean}
                                checked={digital_ocean}
                            />
                            <img
                                className={`img-fluid cloud-selection-image p-2
                ${selectedChoices === 3 && !digital_ocean ? 'cursor-na' : 'cursor-pointer'}
                `}
                                src={digitalOcean_logo}
                                alt='Digital Ocean'
                            />
                            <span className='cloud-checkbox-checkmark'></span>
                        </label>
                    </div>
                    <div
                        className={`col-sm col-5 mt-2 shadow mx-2 ${
                            ibm_cloud ? 'border-teal-cloud' : ''
                        }`}>
                        <label
                            className={`cloud-checkbox-container 
            ${selectedChoices === 3 && !ibm_cloud ? 'cursor-na' : 'cursor-pointer'}
            `}>
                            <input
                                type='checkbox'
                                name='ibm_cloud'
                                onChange={(e) => clickedCloud(e)}
                                disabled={selectedChoices === 3 && !ibm_cloud}
                                checked={ibm_cloud}
                            />
                            <img
                                className={`img-fluid cloud-selection-image p-2
                ${selectedChoices === 3 && !ibm_cloud ? 'cursor-na' : 'cursor-pointer'}
                `}
                                src={ibm_cloud_logo}
                                alt='IBM'
                            />
                            <span className='cloud-checkbox-checkmark'></span>
                        </label>
                    </div>
                    <div
                        className={`col-sm col-5 mt-2 shadow mx-2 ${
                            up_cloud ? 'border-teal-cloud' : ''
                        }`}>
                        <label
                            className={`cloud-checkbox-container 
            ${selectedChoices === 3 && !up_cloud ? 'cursor-na' : 'cursor-pointer'}
            `}>
                            <input
                                type='checkbox'
                                name='up_cloud'
                                onChange={(e) => clickedCloud(e)}
                                disabled={selectedChoices === 3 && !up_cloud}
                                checked={up_cloud}
                            />
                            <img
                                className={`img-fluid cloud-selection-image p-2
                ${selectedChoices === 3 && !up_cloud ? 'cursor-na' : 'cursor-pointer'}
                `}
                                src={upcloud_logo}
                                alt='Upcloud'
                            />
                            <span className='cloud-checkbox-checkmark'></span>
                        </label>
                    </div>
                    <div
                        className={`col-sm col-5 mt-2 shadow mx-2 ${
                            your_own_cloud ? 'border-teal-cloud' : ''
                        }`}>
                        <label
                            className={`cloud-checkbox-container 
            ${selectedChoices === 3 && !your_own_cloud ? 'cursor-na' : 'cursor-pointer'}
            `}>
                            <input
                                type='checkbox'
                                name='your_own_cloud'
                                onChange={(e) => clickedCloud(e)}
                                disabled={selectedChoices === 3 && !your_own_cloud}
                                checked={your_own_cloud}
                            />
                            <img
                                className={`img-fluid cloud-selection-image p-2
                ${selectedChoices === 3 && !your_own_cloud ? 'cursor-na' : 'cursor-pointer'}
                `}
                                src={your_cloud}
                                alt='Cloud'
                            />
                            <span className='cloud-checkbox-checkmark'></span>
                        </label>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    cloudOptions: state.cloudOptions,
})

export default connect(mapStateToProps, {
    switchCloudOption,
})(CloudOptions)

CloudOptions.propTypes = {
    cloudOptions: PropTypes.object,
    switchCloudOption: PropTypes.func,
}
