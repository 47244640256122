import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Spacing from '../../components/partials/Spacing'
import aiven_horizontal from '../../resources/images/cloud/aiven_horizontal.svg'
import amazon_msk_logo from '../../resources/images/cloud/amazon_msk.svg'
import azure_HDInsight from '../../resources/images/cloud/azure_HDInsight.svg'
import confluent_cloud_logo from '../../resources/images/cloud/confluent_cloud.svg'
import instaclustr_logo from '../../resources/images/cloud/instaclustr.svg'

import { connect } from 'react-redux'
import { switchCloudService } from '../../actions/cloudServicesActions'

const ServiceWorkForYou = ({ cloudOptions, cloudFeatures, cloudServices, switchCloudService }) => {
    const { aiven, amazon_msk, azure_hd_insight, confluent_cloud, instaclustr } = cloudServices

    const clickedService = (e) => {
        switchCloudService(e.target)
    }

    const [initialPageRender, setInitialPageRender] = useState({
        timesLoaded: 0,
    })

    const { timesLoaded } = initialPageRender

    const handleActiveServices = (cloudOptions, cloudFeatures, cloudServices) => {
        // Reset all
        cloudServices.aiven = false
        cloudServices.amazon_msk = false
        cloudServices.azure_hd_insight = false
        cloudServices.confluent_cloud = false
        cloudServices.instaclustr = false

        // First parse based on Options
        let servicesonOptions = returnServicesBasedOnActiveOptions(cloudOptions, cloudServices)
        // Then parse based on Features
        let servicesonFeatures = returnServicesBasedOnActiveFeatures(
            cloudFeatures,
            servicesonOptions,
        )
        let selectedServices = convertPointsToBooleanState(servicesonFeatures)
        return selectedServices
    }

    const convertPointsToBooleanState = (servicesonFeatures, cloudServices) => {
        // Getting max values of object
        let arr = Object.values(servicesonFeatures)
        let max = Math.max(...arr)
        // Initializing return array
        let returnArray = { ...cloudServices }
        // Setting true if value equals max points
        Object.entries(servicesonFeatures).map((entry) => {
            if (entry[1] === max) {
                returnArray[entry[0]] = true
            } else {
                returnArray[entry[0]] = false
            }
        })
        return returnArray
    }

    const returnServicesBasedOnActiveOptions = (cloudOptions) => {
        let arrayOfServices = {
            aiven: 0,
            amazon_msk: 0,
            azure_hd_insight: 0,
            confluent_cloud: 0,
            instaclustr: 0,
        }

        if (cloudOptions.aws) {
            arrayOfServices.aiven++
            arrayOfServices.amazon_msk++
            arrayOfServices.confluent_cloud++
            arrayOfServices.instaclustr++
        }
        if (cloudOptions.google_cloud) {
            arrayOfServices.aiven++
            arrayOfServices.azure_hd_insight++
            arrayOfServices.confluent_cloud++
            arrayOfServices.instaclustr++
        }
        if (cloudOptions.azure) {
            arrayOfServices.aiven++
            arrayOfServices.azure_hd_insight++
            arrayOfServices.confluent_cloud++
            arrayOfServices.instaclustr++
        }
        if (cloudOptions.digital_ocean) {
            arrayOfServices.aiven++
        }
        if (cloudOptions.ibm_cloud) {
            arrayOfServices.instaclustr++
        }
        if (cloudOptions.up_cloud) {
            arrayOfServices.aiven++
        }
        if (cloudOptions.your_own_cloud) {
            arrayOfServices.aiven++
            arrayOfServices.azure_hd_insight++
            arrayOfServices.confluent_cloud++
            arrayOfServices.instaclustr++
        }

        return arrayOfServices
    }
    const returnServicesBasedOnActiveFeatures = (cloudFeatures, servicesonOptions) => {
        let arrayOfServices = { ...servicesonOptions }

        if (cloudFeatures.mirror_maker) {
            arrayOfServices.aiven++
        }
        if (cloudFeatures.schema_reg) {
            arrayOfServices.aiven++
            arrayOfServices.amazon_msk++
            arrayOfServices.azure_hd_insight++
            arrayOfServices.confluent_cloud++
            arrayOfServices.instaclustr++
        }

        if (cloudFeatures.kafka_connect) {
            arrayOfServices.aiven++
            arrayOfServices.azure_hd_insight++
            arrayOfServices.confluent_cloud++
            arrayOfServices.instaclustr++
        }

        if (cloudFeatures.kafka_rest_proxy) {
            arrayOfServices.aiven++
        }

        return arrayOfServices
    }

    useEffect(() => {
        try {
            let objectOfActiveServices = handleActiveServices(
                cloudOptions,
                cloudFeatures,
                cloudServices,
            )
            Object.entries(objectOfActiveServices).map(function (service) {
                //   // Match function values
                //   // .name=service[0]
                //   // .checked=service[1]
                const serviceValue = {
                    name: service[0],
                    checked: service[1],
                }
                switchCloudService(serviceValue)
            })
        } catch (err) {
            console.log(err)
        }
    }, [cloudOptions, cloudFeatures])

    useEffect(() => {
        try {
            if (timesLoaded < 2) {
                setInitialPageRender({
                    timesLoaded: timesLoaded + 1,
                })
            } else {
                const splunkData = {
                    cloudOptions: cloudOptions,
                    cloudFeatures: cloudFeatures,
                    cloudServices: cloudServices,
                }
                // Log data to Splunk
                window.Mint.logEvent(
                    'action=cloud_comparison value=' +
                        JSON.stringify(splunkData) +
                        ' page=' +
                        window.location.pathname +
                        ' time=' +
                        Date.now(),
                )
            }
        } catch (err) {
            console.log(err)
        }
    }, [cloudServices])

    return (
        <Fragment>
            <Spacing />
            <div className='mb-5 mt-3 mobile-margin-top'>
                <p className='cera-pro f-32 fw-900 sm-lh-1'>
                    Which Apache Kafka could work for you?
                </p>
                <p className='f-18'>
                    The relevant services are highlighted based on your selections.
                </p>
            </div>
            <div className='container-1'>
                <div className='d-flex flex-wrap justify-content-center'>
                    <div
                        className={`col-sm col-5 mt-2 shadow mx-2 ${
                            aiven ? 'border-red-cloud' : ''
                        } `}>
                        <label className='cloud-checkbox-container cursor-pointer'>
                            <input
                                type='checkbox'
                                name='aiven'
                                onChange={(e) => clickedService(e)}
                                checked={aiven}
                            />
                            <img
                                className='img-fluid service-selection-image'
                                src={aiven_horizontal}
                                alt='Aiven'
                            />
                            <span className='cloud-checkbox-checkmark'></span>
                        </label>
                    </div>
                    <div
                        className={`col-sm col-5 mt-2 shadow mx-2 ${
                            amazon_msk ? 'border-red-cloud' : ''
                        } `}>
                        <label className='cloud-checkbox-container cursor-pointer'>
                            <input
                                type='checkbox'
                                name='amazon_msk'
                                onChange={(e) => clickedService(e)}
                                checked={amazon_msk}
                            />
                            <img
                                className='img-fluid service-selection-image'
                                src={amazon_msk_logo}
                                alt='amazon MSK'
                            />
                            <span className='cloud-checkbox-checkmark'></span>
                        </label>
                    </div>
                    <div
                        className={`col-sm col-5 mt-2 shadow mx-2 ${
                            azure_hd_insight ? 'border-red-cloud' : ''
                        } `}>
                        <label className='cloud-checkbox-container cursor-pointer'>
                            <input
                                type='checkbox'
                                name='azure_hd_insight'
                                onChange={(e) => clickedService(e)}
                                checked={azure_hd_insight}
                            />
                            <img
                                className='img-fluid service-selection-image'
                                src={azure_HDInsight}
                                alt='azure HDInsight'
                            />
                            <span className='cloud-checkbox-checkmark'></span>
                        </label>
                    </div>
                    <div
                        className={`col-sm col-5 mt-2 shadow mx-2 ${
                            confluent_cloud ? 'border-red-cloud' : ''
                        } `}>
                        <label className='cloud-checkbox-container cursor-pointer'>
                            <input
                                type='checkbox'
                                name='confluent_cloud'
                                onChange={(e) => clickedService(e)}
                                checked={confluent_cloud}
                            />
                            <img
                                className='img-fluid service-selection-image'
                                src={confluent_cloud_logo}
                                alt='Confluent Cloud'
                            />
                            <span className='cloud-checkbox-checkmark'></span>
                        </label>
                    </div>

                    <div
                        className={`col-sm col-5 mt-2 shadow mx-2 ${
                            instaclustr ? 'border-red-cloud' : ''
                        } `}>
                        <label className='cloud-checkbox-container cursor-pointer'>
                            <input
                                type='checkbox'
                                name='instaclustr'
                                onChange={(e) => clickedService(e)}
                                checked={instaclustr}
                            />
                            <img
                                className='img-fluid service-selection-image'
                                src={instaclustr_logo}
                                alt='Instaclustr'
                            />
                            <span className='cloud-checkbox-checkmark'></span>
                        </label>
                    </div>
                </div>
                <div className='d-none d-sm-block'>
                    <div className='d-flex flex-wrap justify-content-center'>
                        <div className='col-sm col-5 mt-4'>
                            <p className='fw-700'> Aiven</p>
                        </div>
                        <div className='col-sm col-5 mt-4'>
                            <p className='fw-700'> Amazon MSK</p>
                        </div>
                        <div className='col-sm col-5 mt-4'>
                            <p className='fw-700'> Azure HDInsight</p>
                        </div>
                        <div className='col-sm col-5 mt-4'>
                            <p className='fw-700'> Confluent Cloud</p>
                        </div>
                        <div className='col-sm col-5 mt-4'>
                            <p className='fw-700'> Instaclustr</p>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    cloudServices: state.cloudServices,
    cloudOptions: state.cloudOptions,
    cloudFeatures: state.cloudFeatures,
})

export default connect(mapStateToProps, {
    switchCloudService,
})(ServiceWorkForYou)

ServiceWorkForYou.propTypes = {
    cloudOptions: PropTypes.object,
    cloudFeatures: PropTypes.object,
    cloudServices: PropTypes.object,
    switchCloudService: PropTypes.func,
}
