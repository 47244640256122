import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { switchCloudFeature } from '../../actions/cloudFeaturesActions'
import Spacing from '../../components/partials/Spacing'

const FeaturesYouNeed = ({ cloudFeatures, switchCloudFeature }) => {
    const { mirror_maker, schema_reg, kafka_connect, kafka_rest_proxy } = cloudFeatures

    const clickedFeature = (e) => {
        switchCloudFeature(e.target)
    }

    return (
        <Fragment>
            <Spacing />
            <p className='cera-pro f-24 fw-700 mb-4 mobile-margin-top'>
                Which features do you need?
            </p>
            <div className='container-1'>
                <div className='d-flex flex-wrap justify-content-center'>
                    <div
                        className={`col-sm col-8 mt-3 shadow mx-2 px-2 pt-2 ${
                            mirror_maker ? 'border-teal-cloud' : ''
                        }`}>
                        <label className='cloud-checkbox-container text-left w-100 pl-5 cursor-pointer'>
                            <input
                                type='checkbox'
                                name='mirror_maker'
                                onChange={(e) => clickedFeature(e)}
                                checked={mirror_maker}
                            />
                            <p className='mb-0 f-16 fw-900 cursor-pointer'> MirrorMaker</p>
                            <span className='cloud-checkbox-checkmark cloud-features-bottom'></span>
                        </label>
                    </div>
                    <div
                        className={`col-sm col-8 mt-3 shadow mx-2 px-2 pt-2 ${
                            schema_reg ? 'border-teal-cloud' : ''
                        }`}>
                        <label className='cloud-checkbox-container text-left w-100 pl-5 cursor-pointer'>
                            <input
                                type='checkbox'
                                name='schema_reg'
                                onChange={(e) => clickedFeature(e)}
                                checked={schema_reg}
                            />
                            <p className='mb-0 f-16 fw-900'>Schema Reg</p>
                            <span className='cloud-checkbox-checkmark cloud-features-bottom'></span>
                        </label>
                    </div>
                    <div
                        className={`col-sm col-8 mt-3 shadow mx-2 px-2 pt-2 ${
                            kafka_connect ? 'border-teal-cloud' : ''
                        }`}>
                        <label className='cloud-checkbox-container text-left w-100 pl-5 cursor-pointer'>
                            <input
                                type='checkbox'
                                name='kafka_connect'
                                onChange={(e) => clickedFeature(e)}
                                checked={kafka_connect}
                            />
                            <p className='mb-0 f-16 fw-900'>Kafka Connect</p>
                            <span className='cloud-checkbox-checkmark cloud-features-bottom'></span>
                        </label>
                    </div>
                    <div
                        className={`col-sm col-8 mt-3 shadow mx-2 px-2 pt-2 ${
                            kafka_rest_proxy ? 'border-teal-cloud' : ''
                        }`}>
                        <label className='cloud-checkbox-container cursor-pointer'>
                            <input
                                type='checkbox'
                                name='kafka_rest_proxy'
                                onChange={(e) => clickedFeature(e)}
                                checked={kafka_rest_proxy}
                            />
                            <p className='mb-0 f-16 fw-900'>Kafka Rest Proxy</p>
                            <span className='cloud-checkbox-checkmark cloud-features-bottom'></span>
                        </label>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    cloudFeatures: state.cloudFeatures,
})

export default connect(mapStateToProps, {
    switchCloudFeature,
})(FeaturesYouNeed)

FeaturesYouNeed.propTypes = {
    cloudFeatures: PropTypes.object,
    switchCloudFeature: PropTypes.func,
}
