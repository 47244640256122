import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import aiven_horizontal from '../../resources/images/cloud/aiven_horizontal.svg'
import amazon_msk_logo from '../../resources/images/cloud/amazon_msk.svg'
import azure_HDInsight from '../../resources/images/cloud/azure_HDInsight.svg'
import confluent_cloud_logo from '../../resources/images/cloud/confluent_cloud.svg'
import instaclustr_logo from '../../resources/images/cloud/instaclustr.svg'

import { connect } from 'react-redux'
import { switchCloudService } from '../../actions/cloudServicesActions'

const SideNav = ({ cloudServices, switchCloudService }) => {
    const { aiven, amazon_msk, azure_hd_insight, confluent_cloud, instaclustr } = cloudServices

    const clickedService = (e) => {
        switchCloudService(e.target)
    }

    useEffect(() => {
        let element = document.querySelector('#comparison-table')
        let tempDistanceFromTop =
            element.getBoundingClientRect().top + document.documentElement.scrollTop

        let sidebar = document.querySelector('.sidenav-managed-kafka-comparison')

        const handleVisibility = () => {
            if (element) {
                if (window.pageYOffset > tempDistanceFromTop) {
                    sidebar.classList.remove('d-none')
                } else {
                    sidebar.classList.add('d-none')
                }
            }
        }
        // Triggering visibility of sidebar based on height
        window.onscroll = () => {
            handleVisibility()
        }
    }, [])

    return (
        <div className='sidenav-managed-kafka-comparison d-none'>
            <div className='d-flex flex-row flex-xl-column  border pt-2 rounded'>
                <div className='col px-0'>
                    <label
                        className={`cloud-checkbox-container rounded cursor-pointer ${
                            aiven ? 'border-red-cloud' : 'border'
                        }`}>
                        <input
                            type='checkbox'
                            name='aiven'
                            onChange={(e) => clickedService(e)}
                            checked={aiven}
                        />
                        <img
                            className='img-sidebar-comparison cursor-pointer'
                            src={aiven_horizontal}
                            alt='Aiven'
                        />
                    </label>
                </div>

                <div className='col px-0'>
                    <label
                        className={`cloud-checkbox-container rounded cursor-pointer ${
                            amazon_msk ? 'border-red-cloud' : 'border'
                        }`}>
                        <input
                            type='checkbox'
                            name='amazon_msk'
                            onChange={(e) => clickedService(e)}
                            checked={amazon_msk}
                        />
                        <img
                            className='img-sidebar-comparison'
                            src={amazon_msk_logo}
                            alt='Amazon MSK'
                        />
                    </label>
                </div>
                <div className='col px-0'>
                    <label
                        className={`cloud-checkbox-container rounded cursor-pointer ${
                            azure_hd_insight ? 'border-red-cloud' : 'border'
                        }`}>
                        <input
                            type='checkbox'
                            name='azure_hd_insight'
                            onChange={(e) => clickedService(e)}
                            checked={azure_hd_insight}
                        />
                        <img
                            className='img-sidebar-comparison'
                            src={azure_HDInsight}
                            alt='Azure HDInsight'
                        />
                    </label>
                </div>
                <div className='col px-0'>
                    <label
                        className={`cloud-checkbox-container rounded cursor-pointer ${
                            confluent_cloud ? 'border-red-cloud' : 'border'
                        }`}>
                        <input
                            type='checkbox'
                            name='confluent_cloud'
                            onChange={(e) => clickedService(e)}
                            checked={confluent_cloud}
                        />
                        <img
                            className='img-sidebar-comparison'
                            src={confluent_cloud_logo}
                            alt='Confluent Cloud'
                        />
                    </label>
                </div>
                <div className='col px-0'>
                    <label
                        className={`cloud-checkbox-container rounded cursor-pointer ${
                            instaclustr ? 'border-red-cloud' : 'border'
                        }`}>
                        <input
                            type='checkbox'
                            name='instaclustr'
                            onChange={(e) => clickedService(e)}
                            checked={instaclustr}
                        />
                        <img
                            className='img-sidebar-comparison'
                            src={instaclustr_logo}
                            alt='Instaclustr'
                        />
                    </label>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    cloudServices: state.cloudServices,
})

export default connect(mapStateToProps, {
    switchCloudService,
})(SideNav)

SideNav.propTypes = {
    cloudServices: PropTypes.object,
    switchCloudService: PropTypes.func,
}
