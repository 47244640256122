import React, { Fragment } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HeaderSEO from '../../components/partials/HeaderSEO'

import Layout from '../../components/Layout'
import Spacing from '../../components/partials/Spacing'
import { StaticImage } from 'gatsby-plugin-image'

import CloudOptions from '../../components/managed-kafka-comparison/CloudOptions'
import SideNav from '../../components/managed-kafka-comparison/SideNav'
import FeaturesYouNeed from '../../components/managed-kafka-comparison/FeaturesYouNeed'
import ServiceWorkForYou from '../../components/managed-kafka-comparison/ServiceWorkForYou'
import ComparisonTables from '../../components/managed-kafka-comparison/ComparisonTables'

const managedKafkaComparisonGuide = () => {
  const data = useStaticQuery(graphql`
    query managedKafkaComparisonGuideSEO {
      allContentfulPages(
        filter: {
          pageReference: {
            pageIdentifier: { eq: "Managed_Kafka_Comparison_Guide" }
          }
        }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node

  const Scroll = () => {
    document
      .querySelector('#cloud_questions')
      .scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  return (
    <Fragment>
      <Layout>
        <HeaderSEO pageSEO={pageSEO} />
        <section>
          <StaticImage
            className="home-fixed-top"
            src="../../resources/images/homepage/hero_lens.svg"
            placeholder="blurred"
            alt="Hero Lense"
          />
          <div className="container-1 hero-margin">
            <div className="d-flex flex-wrap align-items-center">
              <div className="col-md-6 ">
                <p className="h4-like">Last Updated: August 2021</p>
                <h1 className="w-75">The Managed Kafka comparison guide</h1>
                <div className="pr-5 pt-5 pb-2">
                  <h2 className="fw-700 f-20 roboto p-text">
                    Which Apache Kafka Managed service should you choose?
                  </h2>
                </div>

                <a onClick={Scroll} className="f-30 cursor-pointer">
                  {' '}
                  <i className="fa fa-arrow-circle-down"> </i>{' '}
                </a>
              </div>
              <div className="col-md-6">
                <StaticImage
                  className="img-fluid mx-auto d-block mobile-margin-top-half"
                  src="../../resources/images/cloud/hero_cloud_comparison.svg"
                  placeholder="tracedSVG"
                  alt="Apache Kafka Cloud Compass"
                />
              </div>
            </div>
          </div>
        </section>
        <Spacing />

        <section className="bg-light-gray">
          <div className="text-center pt-5">
            <p className="f-32 fw-900 cera-pro sm-lh-1">
              Optimizing your Kafka in {new Date().getFullYear()} and beyond
            </p>
          </div>
          <div className="container-1 mt-5 pb-5">
            <div className="d-flex flex-wrap justify-content-center">
              <div className="col-11">
                <div className="text-center ">
                  <p className="fw-300">
                    Some traditional businesses are being pushed to the cloud
                    for the first time. Digital natives are assessing or
                    refining their single or multi-cloud strategy.
                  </p>
                  <p className="fw-300">
                    We now know that cloud migrations aren’t a one-time- 2-month
                    project, but an ongoing process. The Kafka Cloud comparison
                    2021 is an unbiased resource to help you assess which
                    managed service may work best for you now and down the line.
                  </p>
                  <p className="fw-300">
                    If you haven’t already, get started by checking these key{' '}
                    <a
                      className="fw-300"
                      href="/blog/2021/01/considerations-moving-apache-kafka-to-cloud/"
                    >
                      considerations when moving your Kafka to the cloud.
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Spacing />
        <section id="cloud_questions">
          <div className="text-center mobile-margin-top">
            <p className="f-32 fw-900 cera-pro sm-lh-1">
              3 questions about your cloud requirements:
            </p>
            <p className="my-2">
              Use the filters to compare the most relevant managed Kafka
              services for you.
            </p>
            <CloudOptions />
            <FeaturesYouNeed />
            <ServiceWorkForYou />
            <ComparisonTables />
            <SideNav />
          </div>
        </section>
        <Spacing />
        <section className="mobile-margin-bottom mobile-margin-top my-5">
          <div className="container-1 bg-dark rounded-1 p-5">
            <div className="d-flex flex-wrap">
              <div className="col-md-12 col-12 px-5 text-center roll-button mobile-padding-reset">
                <p className="mb-5 paragraph-title text-white lh-30 f-28 cera-pro fw-900">
                  Managed service selected? <br />
                  See what further tooling you might need:
                </p>
                <a
                  className="bg-white primary-text"
                  href="/kafka-roi-calculator/"
                >
                  <span data-hover="Calculate Kafka productivity">
                    {' '}
                    Calculate Kafka productivity
                  </span>
                </a>
              </div>
            </div>
          </div>
        </section>

        <Spacing />
      </Layout>
    </Fragment>
  )
}

export default managedKafkaComparisonGuide
