import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import amazon_msk_logo from '../../resources/images/cloud/amazon_msk.svg'
import azure_HDInsight from '../../resources/images/cloud/azure_HDInsight.svg'
import aiven_horizontal from '../../resources/images/cloud/aiven_horizontal.svg'
import confluent_cloud_logo from '../../resources/images/cloud/confluent_cloud.svg'
import instaclustr_logo from '../../resources/images/cloud/instaclustr.svg'

import azure from '../../resources/images/cloud/azure.svg'
import aws from '../../resources/images/cloud/aws.svg'
import digitalOcean_logo from '../../resources/images/cloud/digitalOcean_logo.svg'
import google_cloud_platform from '../../resources/images/cloud/google_cloud_platform.svg'
import ibm_cloud from '../../resources/images/cloud/ibm_cloud.svg'
import upcloud_logo from '../../resources/images/cloud/upcloud_logo.svg'
import your_cloud from '../../resources/images/cloud/your_cloud.svg'
import { connect } from 'react-redux'

const ComparisonTables = ({ cloudServices }) => {
    const { aiven, amazon_msk, azure_hd_insight, confluent_cloud, instaclustr } = cloudServices

    const [initialPageRender, setInitialPageRender] = useState({
        initialLoad: true,
    })

    const { initialLoad } = initialPageRender

    useEffect(() => {
        try {
            if (aiven || amazon_msk || azure_hd_insight || confluent_cloud || instaclustr) {
                setInitialPageRender({
                    initialLoad: false,
                })
            }
        } catch (err) {
            console.log(err)
        }
    }, [cloudServices])

    return (
        <Fragment>
            <p id='comparison-table' className='cera-pro f-24 fw-700 mt-5'>
                Overview
            </p>
            <div className='container-1'>
                <table className='table table-responsive table-pricing'>
                    <thead>
                        <tr className='border-pricing-team '>
                            <th
                                className='border-right border-pricing-team pt-4 pl-4 footer-color w-20'
                                scope='col'>
                                Service
                            </th>
                            <th
                                className='border-right border-pricing-team pt-4 text-center footer-color w-30'
                                scope='col'>
                                Great for...
                            </th>
                            <th
                                className='border-right border-pricing-team pt-4 text-center footer-color w-15'
                                scope='col'>
                                Superpowers
                            </th>
                            <th
                                className='border-right border-pricing-team pt-4 text-center footer-color w-20'
                                scope='col'>
                                Pricing Model
                            </th>
                            <th
                                className='border-right border-pricing-team pt-4 text-center footer-color'
                                scope='col'>
                                Get Started
                            </th>
                        </tr>
                    </thead>
                    <tbody className='border-pricing-table'>
                        {aiven || initialLoad ? (
                            <tr>
                                <td className='align-middle'>
                                    <img
                                        className='img-fluid img-comparison-table'
                                        src={aiven_horizontal}
                                        alt='Aiven'
                                    />
                                    The managed Kafka in any cloud you want.
                                </td>
                                <td className='align-middle'>
                                    Great for those who want an all-in-one Kafka with fast, reliable
                                    set up, no vendor lock in, and real human support.
                                </td>
                                <td className='align-middle'>
                                    <ul>
                                        <li className='text-left f-16'>Open</li>
                                        <li className='text-left f-16'>Mature</li>
                                        <li className='text-left f-16'>Reliable</li>
                                    </ul>
                                </td>
                                <td className='align-middle'>
                                    Compute/storage utilization (all inclusive price, including
                                    network costs)
                                </td>
                                <td className='align-middle'>
                                    <a href='/cloud/aiven/'>More info on Aiven for Kafka &gt;</a>
                                    <p className='f-14'>(with developer tooling)</p>
                                </td>
                            </tr>
                        ) : (
                            <></>
                        )}
                        {amazon_msk || initialLoad ? (
                            <tr>
                                <td className='align-middle'>
                                    <img
                                        className='img-fluid img-comparison-table'
                                        src={amazon_msk_logo}
                                        alt='AWS MSK'
                                    />
                                    The one with open tooling that plays well with AWS.
                                </td>
                                <td className='align-middle'>
                                    Great for those who have set up an AWS shop and want granular
                                    control over their environment.
                                </td>
                                <td className='align-middle'>
                                    <ul>
                                        <li className='text-left f-16'>Native to AWS</li>
                                        <li className='text-left f-16'>Highly customizable</li>
                                        <li className='text-left f-16'>Supporting Services</li>
                                    </ul>
                                </td>
                                <td className='align-middle'>Resource consumption</td>
                                <td className='align-middle'>
                                    <a href='/cloud/aws-msk/'>More info on AWS MSK &gt;</a>
                                    <p className='f-14'>(with developer tooling)</p>
                                </td>
                            </tr>
                        ) : (
                            <></>
                        )}
                        {azure_hd_insight || initialLoad ? (
                            <tr>
                                <td className='align-middle'>
                                    <img
                                        className='img-fluid img-comparison-table'
                                        src={azure_HDInsight}
                                        alt='Azure HDInsight'
                                    />
                                    The Microsoft-backed Enterprise Kafka with a powerful big data
                                    ecosystem.
                                </td>
                                <td className='align-middle'>
                                    Great for a broad range of advanced messaging scenarios with
                                    Azure analytics and DevOps components.
                                </td>
                                <td className='align-middle'>
                                    <ul>
                                        <li className='text-left f-16'>
                                            Microsoft backed OSS distribution
                                        </li>
                                        <li className='text-left f-16'>
                                            Enterprise Grade Security
                                        </li>
                                        <li className='text-left f-16'>
                                            Lowest Price to Performance Ratio
                                        </li>
                                    </ul>
                                </td>
                                <td className='align-middle'>Cluster billed per minute</td>
                                <td className='align-middle'>
                                    <a href='/cloud/azure/'>
                                        More info on Azure{' '}
                                        <span className='text-nowrap'> HDInsight &gt;</span>
                                    </a>
                                    <p className='f-14'>(with developer tooling)</p>
                                </td>
                            </tr>
                        ) : (
                            <></>
                        )}
                        {confluent_cloud || initialLoad ? (
                            <tr>
                                <td className='align-middle'>
                                    <img
                                        className='img-fluid img-comparison-table mb-1'
                                        src={confluent_cloud_logo}
                                        alt='Confluent cloud'
                                    />
                                    The one with fully-managed event streaming.
                                </td>
                                <td className='align-middle'>
                                    Great for developers and enterprises who want to go ‘ops free’.
                                </td>
                                <td className='align-middle'>
                                    <ul>
                                        <li className='text-left f-16'>Serverless</li>
                                        <li className='text-left f-16'>Complete</li>
                                        <li className='text-left f-16'>Flexible</li>
                                    </ul>
                                </td>
                                <td className='align-middle'>Resource consumption</td>
                                <td className='align-middle'>
                                    <a href='https://help.lenses.io/quickstart/managed-kafka/confluent-cloud/'>
                                        Docs Confluent with Lenses &gt;
                                    </a>{' '}
                                    <br />
                                    <br />
                                    <a
                                        href='https://www.confluent.io/download/'
                                        target='_blank'
                                        rel='noopener noreferrer'>
                                        Download Confluent
                                        <span className='text-nowrap'>Cloud &gt;</span>
                                    </a>
                                </td>
                            </tr>
                        ) : (
                            <></>
                        )}
                        {instaclustr || initialLoad ? (
                            <tr>
                                <td className='align-middle'>
                                    <img
                                        className='img-fluid img-comparison-table'
                                        src={instaclustr_logo}
                                        alt='Instacluster'
                                    />
                                    The truly open-source one.
                                </td>
                                <td className='align-middle'>
                                    Great for those who want to scale applications without license
                                    fees or lock-in.
                                </td>
                                <td className='align-middle'>
                                    <ul>
                                        <li className='text-left f-16'>Dedicated support</li>
                                        <li className='text-left f-16'>Best-of-breed services</li>
                                        <li className='text-left f-16'>Consulting available</li>
                                    </ul>
                                </td>
                                <td className='align-middle'>
                                    <ul>
                                        <li className='text-left f-16'>
                                            All inclusive pricing for infra & support
                                        </li>
                                        <li className='text-left f-16'>
                                            Run in your own cloud with per-node support fee
                                        </li>
                                    </ul>
                                </td>
                                <td className='align-middle'>
                                    <a href='https://help.lenses.io/quickstart/managed-kafka/instaclustr/'>
                                        Docs Instaclustr with Lenses &gt;
                                    </a>{' '}
                                </td>
                            </tr>
                        ) : (
                            <></>
                        )}
                    </tbody>
                </table>

                {/* {initialLoad && <span>hello world</span>} */}
            </div>

            <p className='cera-pro f-24 fw-700 mt-5 pt-4 pb-3'>Hosted by...</p>
            <div className='container-1'>
                <table className='table table-responsive table-pricing'>
                    <thead>
                        <tr className='border-pricing-team '>
                            <th
                                className='border-right border-pricing-team pt-4 pl-4 footer-color align-middle w-15'
                                scope='col'>
                                Service
                            </th>
                            <th
                                className='border-right border-pricing-team text-center footer-color '
                                scope='col'>
                                <img
                                    className='img-fluid img-comparison-table-th'
                                    src={aws}
                                    alt='AWS'
                                />
                            </th>
                            <th
                                className='border-right border-pricing-team text-center footer-color '
                                scope='col'>
                                <img
                                    className='img-fluid img-comparison-table-th'
                                    src={google_cloud_platform}
                                    alt='Google Cloud Platform'
                                />
                            </th>
                            <th
                                className='border-right border-pricing-team text-center footer-color '
                                scope='col'>
                                <img
                                    className='img-fluid img-comparison-table-th'
                                    src={azure}
                                    alt='Azure'
                                />
                            </th>
                            <th
                                className='border-right border-pricing-team text-center footer-color '
                                scope='col'>
                                <img
                                    className='img-fluid img-comparison-table-th'
                                    src={ibm_cloud}
                                    alt='IBM'
                                />
                            </th>
                            <th
                                className='border-right border-pricing-team text-center footer-color '
                                scope='col'>
                                <img
                                    className='img-fluid img-comparison-table-th'
                                    src={upcloud_logo}
                                    alt='Up cloud'
                                />
                            </th>
                            <th
                                className='border-right border-pricing-team text-center footer-color'
                                scope='col'>
                                <img
                                    className='img-fluid img-comparison-table-th'
                                    src={digitalOcean_logo}
                                    alt='Digital Ocean'
                                />
                            </th>
                            <th
                                className='border-right border-pricing-team text-center footer-color'
                                scope='col'>
                                <img
                                    className='img-fluid img-comparison-table-th'
                                    src={your_cloud}
                                    alt='Cloud'
                                />
                            </th>
                        </tr>
                    </thead>
                    <tbody className='border-pricing-table'>
                        {aiven || initialLoad ? (
                            <tr>
                                <td className='align-middle'>
                                    <img
                                        className='img-fluid img-comparison-table'
                                        src={aiven_horizontal}
                                        alt='Aiven'
                                    />
                                </td>
                                <td className='f-20 align-middle'>&#10004;</td>
                                <td className='f-20 align-middle'>&#10004;</td>
                                <td className='f-20 align-middle'>&#10004;</td>
                                <td className='f-20 align-middle'></td>
                                <td className='f-20 align-middle'>&#10004;</td>
                                <td className='f-20 align-middle'>&#10004;</td>
                                <td className='f-20 align-middle'></td>
                            </tr>
                        ) : (
                            <></>
                        )}
                        {amazon_msk || initialLoad ? (
                            <tr>
                                <td className='align-middle'>
                                    <img
                                        className='img-fluid img-comparison-table'
                                        src={amazon_msk_logo}
                                        alt='AWS MSK'
                                    />
                                </td>
                                <td className='f-20 align-middle'>&#10004;</td>
                                <td className='align-middle'></td>
                                <td className='align-middle'></td>
                                <td className='align-middle'></td>
                                <td className='align-middle'></td>
                                <td className='align-middle'></td>
                                <td className='align-middle'></td>
                            </tr>
                        ) : (
                            <></>
                        )}
                        {azure_hd_insight || initialLoad ? (
                            <tr>
                                <td className='align-middle'>
                                    <img
                                        className='img-fluid img-comparison-table'
                                        src={azure_HDInsight}
                                        alt='Azure HDInsight'
                                    />
                                </td>
                                <td className='align-middle'></td>
                                <td className='align-middle'></td>
                                <td className='f-20 align-middle'>&#10004;</td>
                                <td className='align-middle'></td>
                                <td className='align-middle'></td>
                                <td className='align-middle'></td>
                                <td className='align-middle'></td>
                            </tr>
                        ) : (
                            <></>
                        )}
                        {confluent_cloud || initialLoad ? (
                            <tr>
                                <td className='align-middle'>
                                    <img
                                        className='img-fluid img-comparison-table'
                                        src={confluent_cloud_logo}
                                        alt='Confluent cloud'
                                    />
                                </td>
                                <td className='f-20 align-middle'>&#10004;</td>
                                <td className='f-20 align-middle'>&#10004;</td>
                                <td className='f-20 align-middle'>&#10004;</td>
                                <td className='align-middle'></td>
                                <td className='align-middle'></td>
                                <td className='align-middle'></td>
                                <td className='f-20 align-middle'>&#10004;</td>
                            </tr>
                        ) : (
                            <></>
                        )}
                        {instaclustr || initialLoad ? (
                            <tr>
                                <td className='align-middle'>
                                    <img
                                        className='img-fluid img-comparison-table'
                                        src={instaclustr_logo}
                                        alt='Instacluster'
                                    />
                                </td>
                                <td className='f-20 align-middle'>&#10004;</td>
                                <td className='f-20 align-middle'>&#10004;</td>
                                <td className='f-20 align-middle'>&#10004;</td>
                                <td className='f-20 align-middle'>&#10004;*</td>
                                <td className='align-middle'></td>
                                <td className='f-20 align-middle'>&#10004;*</td>
                                <td className='f-20 align-middle'>&#10004;*</td>
                            </tr>
                        ) : (
                            <></>
                        )}
                    </tbody>
                </table>
                {instaclustr || initialLoad ? (
                    <div className='text-nowrap text-right'>
                        *Via &lsquo;run in customer managed infrastructure&rsquo;
                    </div>
                ) : (
                    <></>
                )}
            </div>

            <p className='cera-pro f-24 fw-700 mt-5 pt-4 pb-3'>Deployment</p>
            <div className='container-1'>
                <table className='table table-responsive table-pricing'>
                    <thead>
                        <tr className='border-pricing-team '>
                            <th
                                className='border-right border-pricing-team pt-4 pl-4 footer-color w-15'
                                scope='col'>
                                Service
                            </th>
                            <th
                                className='border-right border-pricing-team pt-4 pl-4 footer-color '
                                scope='col'>
                                Kafka version
                            </th>
                            <th
                                className='border-right border-pricing-team pt-4 text-center footer-color '
                                scope='col'>
                                Single/Multi Tenant
                            </th>
                            <th
                                className='border-right border-pricing-team pt-4 text-center footer-color '
                                scope='col'>
                                Terraform support
                            </th>
                        </tr>
                    </thead>
                    <tbody className='border-pricing-table'>
                        {aiven || initialLoad ? (
                            <tr>
                                <td className='align-middle'>
                                    <img
                                        className='img-fluid img-comparison-table'
                                        src={aiven_horizontal}
                                        alt='Aiven'
                                    />
                                </td>
                                <td className='align-middle'>2.8</td>
                                <td className='align-middle'>Single by default</td>
                                <td className='f-20 align-middle'>&#10004;</td>
                            </tr>
                        ) : (
                            <></>
                        )}
                        {amazon_msk || initialLoad ? (
                            <tr>
                                <td className='align-middle'>
                                    <img
                                        className='img-fluid img-comparison-table'
                                        src={amazon_msk_logo}
                                        alt='AWS MSK'
                                    />
                                </td>
                                <td className='align-middle'>2.7 (2.2.1 recommended)</td>
                                <td className='align-middle'>Single</td>
                                <td className='align-middle'></td>
                            </tr>
                        ) : (
                            <></>
                        )}
                        {azure_hd_insight || initialLoad ? (
                            <tr>
                                <td className='align-middle'>
                                    <img
                                        className='img-fluid img-comparison-table'
                                        src={azure_HDInsight}
                                        alt='Azure HDInsight'
                                    />
                                </td>
                                <td className='align-middle'>2.4.1 (early April)</td>
                                <td className='align-middle'>Single</td>
                                <td className='f-20 align-middle'>&#10004;</td>
                            </tr>
                        ) : (
                            <></>
                        )}
                        {confluent_cloud || initialLoad ? (
                            <tr>
                                <td className='align-middle'>
                                    <img
                                        className='img-fluid img-comparison-table'
                                        src={confluent_cloud_logo}
                                        alt='Confluent cloud'
                                    />
                                </td>
                                <td className='align-middle'>2.7</td>
                                <td className='align-middle'>Either</td>
                                <td className='f-20 align-middle'>&#10004;</td>
                            </tr>
                        ) : (
                            <></>
                        )}
                        {instaclustr || initialLoad ? (
                            <tr>
                                <td className='align-middle'>
                                    <img
                                        className='img-fluid img-comparison-table'
                                        src={instaclustr_logo}
                                        alt='Instacluster'
                                    />
                                </td>
                                <td className='align-middle'>2.5.1</td>
                                <td className='align-middle'>Single by default</td>
                                <td className='f-20 align-middle'>&#10004;</td>
                            </tr>
                        ) : (
                            <></>
                        )}
                    </tbody>
                </table>
            </div>

            <p className='cera-pro f-24 fw-700 mt-5 pt-4 pb-3'>Supporting Services</p>
            <div className='container-1'>
                <table className='table table-responsive table-pricing'>
                    <thead>
                        <tr className='border-pricing-team '>
                            <th
                                className='border-right border-pricing-team pt-4 pl-4 footer-color w-15'
                                scope='col'>
                                Service
                            </th>
                            <th
                                className='border-right border-pricing-team pt-4 pl-4 footer-color '
                                scope='col'>
                                Managed MirrorMaker
                            </th>
                            <th
                                className='border-right border-pricing-team pt-4 text-center footer-color '
                                scope='col'>
                                Schema Registry
                            </th>
                            <th
                                className='border-right border-pricing-team pt-4 text-center footer-color '
                                scope='col'>
                                Kafka Connect
                            </th>
                            <th
                                className='border-right border-pricing-team pt-4 text-center footer-color '
                                scope='col'>
                                Kafka Rest Proxy
                            </th>
                        </tr>
                    </thead>
                    <tbody className='border-pricing-table'>
                        {aiven || initialLoad ? (
                            <tr>
                                <td className='align-middle'>
                                    <img
                                        className='img-fluid img-comparison-table'
                                        src={aiven_horizontal}
                                        alt='Aiven'
                                    />
                                </td>
                                <td className='f-20 align-middle'>&#10004;</td>
                                <td className='f-20 align-middle'>
                                    &#10004; <span className='f-16'> (free)</span>
                                </td>
                                <td className='f-20 align-middle'>&#10004;</td>
                                <td className='f-20 align-middle'>
                                    &#10004; <span className='f-16'>(free) </span>
                                </td>
                            </tr>
                        ) : (
                            <></>
                        )}
                        {amazon_msk || initialLoad ? (
                            <tr>
                                <td className='align-middle'>
                                    <img
                                        className='img-fluid img-comparison-table'
                                        src={amazon_msk_logo}
                                        alt='AWS MSK'
                                    />
                                </td>
                                <td className='align-middle'></td>
                                <td className='f-20 align-middle'>
                                    &#10004;<span className='f-16'>(with glue)</span>
                                </td>
                                <td className='align-middle'></td>
                                <td className='align-middle'></td>
                            </tr>
                        ) : (
                            <></>
                        )}
                        {azure_hd_insight || initialLoad ? (
                            <tr>
                                <td className='align-middle'>
                                    <img
                                        className='img-fluid img-comparison-table'
                                        src={azure_HDInsight}
                                        alt='Azure HDInsight'
                                    />
                                </td>
                                <td className='align-middle'></td>
                                <td className='f-20 align-middle'>&#10004;</td>
                                <td className='f-20 align-middle'>&#10004;</td>
                                <td className='f-20 align-middle'>&#10004;</td>
                            </tr>
                        ) : (
                            <></>
                        )}
                        {confluent_cloud || initialLoad ? (
                            <tr>
                                <td className='align-middle'>
                                    <img
                                        className='img-fluid img-comparison-table'
                                        src={confluent_cloud_logo}
                                        alt='Confluent cloud'
                                    />
                                </td>
                                <td className='align-middle'>
                                    <a
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        href='https://docs.confluent.io/kafka-connect-replicator/current/index.html '>
                                        Confluent Replicator
                                    </a>
                                </td>
                                <td className='f-20 align-middle'>&#10004;</td>
                                <td className='f-20 align-middle'>&#10004;</td>
                                <td className='align-middle'></td>
                            </tr>
                        ) : (
                            <></>
                        )}
                        {instaclustr || initialLoad ? (
                            <tr>
                                <td className='align-middle'>
                                    <img
                                        className='img-fluid img-comparison-table'
                                        src={instaclustr_logo}
                                        alt='Instacluster'
                                    />
                                </td>
                                <td className='f-20 align-middle'>&#10004;</td>
                                <td className='f-20 align-middle'>&#10004;</td>
                                <td className='f-20 align-middle'>&#10004;</td>
                                <td className='f-20 align-middle'>&#10004;</td>
                            </tr>
                        ) : (
                            <></>
                        )}
                    </tbody>
                </table>
            </div>

            <p className='cera-pro f-24 fw-700 mt-5 pt-4 pb-3'>Core Service Features</p>
            <div className='container-1'>
                <table className='table table-responsive table-pricing'>
                    <thead>
                        <tr className='border-pricing-team '>
                            <th
                                className='border-right border-pricing-team pt-4 pl-4 footer-color w-15'
                                scope='col'>
                                Service
                            </th>
                            <th
                                className='border-right border-pricing-team pt-4 pl-4 footer-color '
                                scope='col'>
                                ACL management
                            </th>
                            <th
                                className='border-right border-pricing-team pt-4 text-center footer-color '
                                scope='col'>
                                No dealing with Zookeepers & cost
                            </th>
                            <th
                                className='border-right border-pricing-team pt-4 text-center footer-color '
                                scope='col'>
                                Access to Infrastructure Performance Metrics
                            </th>
                            <th
                                className='border-right border-pricing-team pt-4 text-center footer-color '
                                scope='col'>
                                Bonus features
                            </th>
                        </tr>
                    </thead>
                    <tbody className='border-pricing-table'>
                        {aiven || initialLoad ? (
                            <tr>
                                <td className='align-middle'>
                                    <img
                                        className='img-fluid img-comparison-table'
                                        src={aiven_horizontal}
                                        alt='Aiven'
                                    />
                                </td>
                                <td className='f-20 align-middle'>&#10004;</td>
                                <td className='f-20 align-middle'>&#10004;</td>
                                <td className='f-20 align-middle'>&#10004;</td>
                                <td className='align-middle'>
                                    <ul>
                                        <li className='text-left f-16'>Real Human Support</li>
                                        <li className='text-left f-16'>
                                            Single and Multi-AZ clusters
                                        </li>
                                        <li className='text-left f-16'>
                                            Multi-Cloud replication and migrations
                                        </li>
                                        <li className='text-left f-16'>
                                            Multi-Region, Global deployments
                                        </li>
                                        <li className='text-left f-16'>
                                            Connect Connectors (30+ fully-managed)
                                        </li>
                                        <li className='text-left f-16'>
                                            Stream processing with Lenses (Lenses SQL)
                                        </li>
                                        <li className='text-left f-16'>
                                            VPC Peering (free of charge)
                                        </li>
                                        <li className='text-left f-16'>AWS Transit Gateway</li>
                                        <li className='text-left f-16'>AWS PrivateLink</li>
                                        <li className='text-left f-16'>
                                            AWS Outposts deployments (on-premises)
                                        </li>
                                        <li className='text-left f-16'>Audit logs</li>
                                        <li className='text-left f-16'>RBAC</li>
                                        <li className='text-left f-16'>
                                            Encryption at-rest and in-transit
                                        </li>
                                        <li className='text-left f-16'>Bug Bounty programs</li>
                                        <li className='text-left f-16'>Penetration test reports</li>
                                        <li className='text-left f-16'>
                                            <a
                                                href='https://karapace.io'
                                                target='_blank'
                                                rel='noopener noreferrer'>
                                                Karapace.io
                                            </a>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        ) : (
                            <></>
                        )}
                        {amazon_msk || initialLoad ? (
                            <tr>
                                <td className='align-middle'>
                                    <img
                                        className='img-fluid img-comparison-table'
                                        src={amazon_msk_logo}
                                        alt='AWS MSK'
                                    />
                                </td>
                                <td className='align-middle'></td>
                                <td className='f-20 align-middle'>&#10004;</td>
                                <td className='f-20 align-middle'>
                                    &#10004;<span className='f-16'> (Cloudwatch)</span>
                                </td>
                                <td className='align-middle'>
                                    <ul>
                                        <li className='text-left f-16'>VPC</li>
                                        <li className='text-left f-16'>KMS at rest</li>
                                        <li className='text-left f-16'>ACM for authentication</li>
                                        <li className='text-left f-16'>IAM for control-plan</li>
                                        <li className='text-left f-16'>Lambda</li>
                                        <li className='text-left f-16'>Kinesis Data Analytics</li>
                                        <li className='text-left f-16'>
                                            Granular pricing for capacity planning
                                        </li>
                                        <li className='text-left f-16'>
                                            Auto-scaling cluster performance
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        ) : (
                            <></>
                        )}
                        {azure_hd_insight || initialLoad ? (
                            <tr>
                                <td className='align-middle'>
                                    <img
                                        className='img-fluid img-comparison-table'
                                        src={azure_HDInsight}
                                        alt='Azure HDInsight'
                                    />
                                </td>
                                <td className='f-20 align-middle'>&#10004;</td>
                                <td className='align-middle'></td>
                                <td className='f-20 align-middle'>&#10004;</td>
                                <td className='align-middle'>
                                    <ul>
                                        <li className='text-left f-16'>
                                            No SaaS tiers to deal with, performance limited only by
                                            infrastructure
                                        </li>
                                        <li className='text-left f-16'>
                                            Single tenant architecture
                                        </li>
                                        <li className='text-left f-16'>
                                            Ranger based fine grained access control
                                        </li>
                                        <li className='text-left f-16'>AD based authentication</li>
                                        <li className='text-left f-16'>Always Encrypted</li>
                                        <li className='text-left f-16'>Growing ISV ecosystem</li>
                                    </ul>
                                </td>
                            </tr>
                        ) : (
                            <></>
                        )}
                        {confluent_cloud || initialLoad ? (
                            <tr>
                                <td className='align-middle'>
                                    <img
                                        className='img-fluid img-comparison-table'
                                        src={confluent_cloud_logo}
                                        alt='Confluent cloud'
                                    />
                                </td>
                                <td className='f-20 align-middle'>&#10004;</td>
                                <td className='f-20 align-middle'>&#10004;</td>
                                <td className='f-20 align-middle'>&#10004;</td>
                                <td className='align-middle'>
                                    <ul>
                                        <li className='text-left f-16'>
                                            Stream processing with ksqlDB
                                        </li>
                                        <li className='text-left f-16'>
                                            120+ self and fully-managed connectors
                                        </li>
                                        <li className='text-left f-16'>
                                            Single and Multi-AZ clusters{' '}
                                        </li>
                                        <li className='text-left f-16'>Schema registry </li>
                                        <li className='text-left f-16'>
                                            Private Networking options (VPC Peering, AWS Transit
                                            Gateway)
                                        </li>
                                        <li className='text-left f-16'>Audit logs</li>
                                        <li className='text-left f-16'>
                                            Role-based access controls{' '}
                                        </li>
                                        <li className='text-left f-16'>
                                            Encryption at-rest and in-transit{' '}
                                        </li>
                                        <li className='text-left f-16'>AWS Private Link & BYOK </li>
                                        <li className='text-left f-16'>
                                            Cloud Data flow & Metrics API{' '}
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        ) : (
                            <></>
                        )}
                        {instaclustr || initialLoad ? (
                            <tr>
                                <td className='align-middle'>
                                    <img
                                        className='img-fluid img-comparison-table'
                                        src={instaclustr_logo}
                                        alt='Instacluster'
                                    />
                                </td>
                                <td className='f-20 align-middle'>&#10004;</td>
                                <td className='f-20 align-middle'>&#10004;</td>
                                <td className='f-20 align-middle'>&#10004;</td>
                                <td className='align-middle'>
                                    <ul>
                                        <li className='text-left f-16'>
                                            24x7 Support included by default
                                        </li>
                                        <li className='text-left f-16'>
                                            Support includes Kafka application issues and bug fixes
                                        </li>
                                        <li className='text-left f-16'>
                                            Availability and Latency SLAs
                                        </li>
                                        <li className='text-left f-16'>
                                            Multi availability zones by default
                                        </li>
                                        <li className='text-left f-16'>VPC Peering</li>
                                        <li className='text-left f-16'>Private Network Clusters</li>
                                        <li className='text-left f-16'>
                                            Encryption at-rest and in-transit
                                        </li>
                                        <li className='text-left f-16'>
                                            Provisioning via Console, API or Terraform Provider
                                        </li>
                                        <li className='text-left f-16'>
                                            Bundled and supported Kafka Connect Connectors
                                        </li>
                                        <li className='text-left f-16'>
                                            Upload and run your own Kafka Connect Connectors
                                        </li>
                                        <li className='text-left f-16'>Lenses compatible</li>
                                        <li className='text-left f-16'>Audit log shipping</li>
                                        <li className='text-left f-16'>
                                            Customer Initiated Resize for vertical scaling
                                        </li>
                                        <li className='text-left f-16'>
                                            Horizontal scaling on request{' '}
                                        </li>
                                        <li className='text-left f-16'>
                                            Cluster configuration backups
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        ) : (
                            <></>
                        )}
                    </tbody>
                </table>
            </div>

            <p className='cera-pro f-24 fw-700 mt-5 pt-4 pb-3'>Compliance</p>
            <div className='container-1'>
                <table className='table table-responsive table-pricing'>
                    <thead>
                        <tr className='border-pricing-team '>
                            <th
                                className='border-right border-pricing-team pt-4 pl-4 footer-color w-15'
                                scope='col'>
                                Service
                            </th>
                            <th
                                className='border-right border-pricing-team pt-4 pl-4 footer-color w-30'
                                scope='col'>
                                Compliance
                            </th>
                            <th
                                className='border-right border-pricing-team pt-4 text-center footer-color '
                                scope='col'>
                                Access to Infrastructure Audits
                            </th>
                            <th
                                className='border-right border-pricing-team pt-4 text-center footer-color '
                                scope='col'>
                                Authentication methods
                            </th>
                        </tr>
                    </thead>
                    <tbody className='border-pricing-table'>
                        {aiven || initialLoad ? (
                            <tr>
                                <td className='align-middle'>
                                    <img
                                        className='img-fluid img-comparison-table'
                                        src={aiven_horizontal}
                                        alt='Aiven'
                                    />
                                </td>
                                <td className='align-middle'>
                                    ISO/IEC 27001:2013, SOC2, GDPR, HIPAA, CCPA,PCI/DSS
                                </td>
                                <td className='align-middle'>
                                    Out-of-the-box integrations included
                                </td>
                                <td className='align-middle'>SSL, SASL/PLAIN, SASL/SCRAM</td>
                            </tr>
                        ) : (
                            <></>
                        )}
                        {amazon_msk || initialLoad ? (
                            <tr>
                                <td className='align-middle'>
                                    <img
                                        className='img-fluid img-comparison-table'
                                        src={amazon_msk_logo}
                                        alt='AWS MSK'
                                    />
                                </td>
                                <td className='align-middle'>
                                    HIPAA eligible, PCI, ISO, SOC 1,2,3
                                </td>
                                <td className='f-20 align-middle'>
                                    &#10004; <span className='f-16'>(Cloudtrail)</span>
                                </td>
                                <td className='align-middle'>SSL, SASL/PLAIN, SASL/SCRAM</td>
                            </tr>
                        ) : (
                            <></>
                        )}
                        {azure_hd_insight || initialLoad ? (
                            <tr>
                                <td className='align-middle'>
                                    <img
                                        className='img-fluid img-comparison-table'
                                        src={azure_HDInsight}
                                        alt='Azure HDInsight'
                                    />
                                </td>
                                <td className='align-middle'>SOC2, PCI/DSS, HIPAA</td>
                                <td className='align-middle'></td>
                                <td className='align-middle'>Kerberos</td>
                            </tr>
                        ) : (
                            <></>
                        )}
                        {confluent_cloud || initialLoad ? (
                            <tr>
                                <td className='align-middle'>
                                    <img
                                        className='img-fluid img-comparison-table'
                                        src={confluent_cloud_logo}
                                        alt='Confluent cloud'
                                    />
                                </td>
                                <td className='align-middle'>
                                    {' '}
                                    SOC 1/2/3, ISO 27001, PCI, CSA (level 1), EBA
                                </td>
                                <td className='align-middle'>
                                    Can get our SOC 1/2/3 and ISO 27001 reports
                                </td>
                                <td className='align-middle'>SASL/SCRAM/SAML/SSO</td>
                            </tr>
                        ) : (
                            <></>
                        )}
                        {instaclustr || initialLoad ? (
                            <tr>
                                <td className='align-middle'>
                                    <img
                                        className='img-fluid img-comparison-table'
                                        src={instaclustr_logo}
                                        alt='Instacluster'
                                    />
                                </td>
                                <td className='align-middle'>SOC2, PCI/DSS,HIPAA</td>
                                <td className='align-middle'>SOC2 and PCI available on request</td>
                                <td className='align-middle'>SASL/SCRAM</td>
                            </tr>
                        ) : (
                            <></>
                        )}
                    </tbody>
                </table>
            </div>
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    cloudServices: state.cloudServices,
})

export default connect(mapStateToProps, {})(ComparisonTables)

ComparisonTables.propTypes = {
    cloudServices: PropTypes.object,
}
