import {
    SWITCH_CLOUD_OPTION_AWS,
    SWITCH_CLOUD_OPTION_GCP,
    SWITCH_CLOUD_OPTION_AZURE,
    SWITCH_CLOUD_OPTION_DIGITAL_OCEAN,
    SWITCH_CLOUD_OPTION_IBM,
    SWITCH_CLOUD_OPTION_UPCLOUD,
    SWITCH_CLOUD_OPTION_YOUR_CLOUD,
} from './types'

export const switchCloudOption = (selectedCloud) => (dispatch) => {
    try {
        if (selectedCloud.name === 'aws') {
            dispatch({
                type: SWITCH_CLOUD_OPTION_AWS,
                payload: selectedCloud.checked,
            })
        } else if (selectedCloud.name === 'google_cloud') {
            dispatch({
                type: SWITCH_CLOUD_OPTION_GCP,
                payload: selectedCloud.checked,
            })
        } else if (selectedCloud.name === 'azure') {
            dispatch({
                type: SWITCH_CLOUD_OPTION_AZURE,
                payload: selectedCloud.checked,
            })
        } else if (selectedCloud.name === 'digital_ocean') {
            dispatch({
                type: SWITCH_CLOUD_OPTION_DIGITAL_OCEAN,
                payload: selectedCloud.checked,
            })
        } else if (selectedCloud.name === 'ibm_cloud') {
            dispatch({
                type: SWITCH_CLOUD_OPTION_IBM,
                payload: selectedCloud.checked,
            })
        } else if (selectedCloud.name === 'up_cloud') {
            dispatch({
                type: SWITCH_CLOUD_OPTION_UPCLOUD,
                payload: selectedCloud.checked,
            })
        } else if (selectedCloud.name === 'your_own_cloud') {
            dispatch({
                type: SWITCH_CLOUD_OPTION_YOUR_CLOUD,
                payload: selectedCloud.checked,
            })
        }
    } catch (error) {
        console.log(error)
    }
}
