import {
    SWITCH_CLOUD_FEATURE_MIRROR_MAKER,
    SWITCH_CLOUD_FEATURE_SCHEMA_REG,
    SWITCH_CLOUD_FEATURE_KAFKA_CONNECT,
    SWITCH_CLOUD_FEATURE_KAFKA_REST_PROXY,
} from './types'

export const switchCloudFeature = (selectedFeature) => (dispatch) => {
    try {
        if (selectedFeature.name === 'mirror_maker') {
            dispatch({
                type: SWITCH_CLOUD_FEATURE_MIRROR_MAKER,
                payload: selectedFeature.checked,
            })
        } else if (selectedFeature.name === 'schema_reg') {
            dispatch({
                type: SWITCH_CLOUD_FEATURE_SCHEMA_REG,
                payload: selectedFeature.checked,
            })
        } else if (selectedFeature.name === 'kafka_connect') {
            dispatch({
                type: SWITCH_CLOUD_FEATURE_KAFKA_CONNECT,
                payload: selectedFeature.checked,
            })
        } else if (selectedFeature.name === 'kafka_rest_proxy') {
            dispatch({
                type: SWITCH_CLOUD_FEATURE_KAFKA_REST_PROXY,
                payload: selectedFeature.checked,
            })
        }
    } catch (error) {
        console.log(error)
    }
}
